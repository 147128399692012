export const useModal = () => {
  const isModalShow = ref(false);

  const openModal = () => {
    isModalShow.value = true;
  };

  const closeModal = () => {
    isModalShow.value = false;
  };

  return { isModalShow, closeModal, openModal };
};
